var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3eqTAUSyYC1BOCDPQElzP"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: publicRuntimeConfig.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.2,
  debug: false,
  replaysOnErrorSampleRate: 0.5,
  replaysSessionSampleRate: 0.05,
  profilesSampleRate: 0.25,
  beforeSendTransaction: transaction => {
    // Ignore crawlers
    if (transaction.contexts?.device?.family === "Spider") {
      return null;
    }
    return transaction;
  },
  beforeSend: event => {
    // Ignore crawlers
    if (event.contexts?.device?.family === "Spider") {
      return null;
    }
    return event;
  },
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: []
    }),
    new Sentry.BrowserProfilingIntegration(),
    new CaptureConsole({ levels: ["error"] })
  ],
  ignoreErrors: [
    "ServiceWorkerContainer.<anonymous>(<anonymous>)",
    "wrsParams.serviceWorkers.navigator.serviceWorker.register(<anonymous>)"
  ]
});
